<template>
  <div>
    <template>
      <v-snackbar
        :timeout="5000"
        :value="true"
        top
        :color="isError ? 'error' : 'success'"
        center
        v-model="snackBar"
        outlined
        elevation="24"
        text
      >
        {{ snackBarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            :color="isError ? 'error' : 'success'"
            text
            v-bind="attrs"
            @click="snackBar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </template>
    <!-- search employee -->
    <v-row class="mb-3">
      <v-col cols="12" sm="12" md="3" class="d-flex justify-content-between">
        <v-text-field
          @keyup.enter="searchEmployees"
          solo
          prepend-inner-icon="mdi-magnify"
          label="Search By Name"
          dense
          clearable
          @click:clear="resetData"
          flat
          color="primary"
          background-color="white lighten-4"
          v-model="query"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-btn color="primary" @click.prevent="searchEmployees">Search</v-btn>
      </v-col>
    </v-row>
    <div v-if="pageIsLoading">
      <spinner></spinner>
    </div>
    <div v-else>
      <v-data-table
        fixed-header
        :headers="headers"
        class="elevation-1"
        disable-sort
        :items="employees"
        :items-per-page="employees.length"
        hide-default-footer
      >
        <template v-slot:[`item.no`]="{ index }">
          {{ index + 1 + pageNumbering }}
        </template>

        <template v-slot:[`item.ssnitContributor`]="{ item }">
          <v-chip
            small
            class="success text-center justify-content-center"
            v-if="item.employeeIdentification?.ssnitContributor"
            style="width: 100px"
            >Yes</v-chip
          >
          <v-chip
            small
            class="error text-center justify-content-center"
            v-if="!item.employeeIdentification?.ssnitContributor"
            style="width: 100px"
            >No</v-chip
          >
        </template>

        <template v-slot:[`item.payrollId`]="{ item }">
          <v-chip
            small
            class="success text-center justify-content-center"
            v-if="item.employeeId"
            style="width: 100px"
            >Yes</v-chip
          >
          <v-chip
            small
            class="error text-center justify-content-center"
            v-if="!item.employeeId"
            style="width: 100px"
            >No</v-chip
          >
        </template>

        <template v-slot:[`item.salary`]="{ item }">
          <span v-if="item.isManager && isConfidential">{{
            formatFigure(item.basicSalary.toFixed(2))
          }}</span>
          <span v-else>{{ formatFigure(item.basicSalary.toFixed(2)) }}</span>
        </template>

        <template v-slot:no-data>
          <div
            class="row justify-content-center d-flex align-items-center py-3"
          >
            <div class="col-md-12">NO DATA AVAILABLE !</div>
            <div class="col-md-12">
              <v-btn color="primary" @click="resetData">Refresh</v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
      <div class="text-center pt-4">
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          @input="paginatePage"
          circle
        ></v-pagination>
      </div>
    </div>
    <v-dialog v-model="slipDialog">
      <preview-payslip
        :data="data"
        :allowances="allowances"
        :bonuses="bonuses"
        :deductions="deductions"
      ></preview-payslip>
    </v-dialog>
  </div>
</template>

<script>
import PreviewPayslip from "../views/PreviewPayslip";
import * as roles from "../services/roles";
import { DOWNLOAD_EMPLOYEE_PAYROLL } from "../services/graphql/mutations/payroll_mutations";

export default {
  components: { PreviewPayslip },

  data: () => ({
    slipDialog: false,
    query: null,
    isError: true,
    snackBar: false,
    data: null,
    bonuses: null,
    deductions: null,
    allowances: null,
    snackBarText: "",
    isloading: false,
    itemsPerPage: 20,
    dialog: false,
    headers: [
      {
        text: "NO.",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "EMPLOYEE NAME", value: "displayName" },
      { text: "EMPLOYEE ID", value: "employeeId" },
      { text: "DEPARTMENT", value: "department" },
      { text: "JOB TITLE", value: "jobTitle" },
      {
        text: "SSNIT CONTRIBUTOR",
        value: "ssnitContributor",
        align: "center",
      },
      { text: "UNIQUE CODE", value: "payrollId", align: "center" },
      { text: "BASIC SALARY", value: "salary", align: "center" },
    ],
    editedIndex: -1,
    editedItem: {},
    trials: 0,
  }),

  computed: {
    currentPage: {
      get() {
        return this.$store.getters["employees/currentPage"];
      },
      set(value) {
        this.$store.commit("employees/SET_CURRENT_PAGE", value);
      },
    },
    lastPage: {
      get() {
        return this.$store.getters["employees/totalPages"];
      },
    },
    pageNumbering() {
      if (this.page === 1) {
        return 0;
      } else {
        return this.page * 25 - 25;
      }
    },
    formTitle() {
      return this.editedIndex === -1;
    },
    employees() {
      return this.$store.getters["employees/allEmployees"];
    },
    hasEmployees() {
      return this.$store.getters["employees/hasEmployees"];
    },
    totalPages() {
      return this.$store.getters["employees/totalPages"];
    },
    pageIsLoading() {
      return this.$store.getters["employees/pageLoading"];
    },
    page: {
      get() {
        return this.$store.state.employees.currentPage;
      },
      set(val) {
        return this.$store.commit("employees/SET_CURRENT_PAGE", val - 1);
      },
    },
    currentYear() {
      return new Date().getFullYear();
    },

    currentMonth() {
      return ("0" + (new Date().getMonth() + 1)).slice(-2);
    },

    isConfidential() {
      return roles.isConfidential();
    },
  },
  async created() {
    await this.initialize();
  },

  methods: {
    formatFigure(item) {
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(item);
    },

    fetchPayrollData(item) {
      const data = {
        employee_id: item.employeeId,
        year: 2021,
        month: 12,
      };
      this.$apollo
        .mutate({
          mutation: DOWNLOAD_EMPLOYEE_PAYROLL,
          variables: {
            downloadPayslipInput: data,
          },
        })
        .then((response) => {
          if (response.data.getEmployeePayslip.message === "Not Approved") {
            this.snackBarText = "Payslip Not Approved";
            this.isError = true;
            this.snackBar = true;
            this.closeSnackBar(3000);
          } else if (response.data.getEmployeePayslip.payroll) {
            this.data = response.data.payroll;
            this.bonuses = response.data.bonuses;
            this.deductions = response.data.deductions;
            this.allowances = response.data.allowances;
            this.slipDialog = true;
          } else {
            this.snackBarText = "No Payslip to print";
            this.isError = true;
            this.snackBar = true;
            this.closeSnackBar(3000);
          }
        })
        .catch(() => {
          this.snackBarText = "Fatal Error";
          this.isError = true;
          this.snackBar = true;
          this.closeSnackBar(3000);
        });
    },

    closeSnackBar(time) {
      setTimeout(() => {
        this.snackBar = false;
      }, time);
    },
    initialize() {
      let page = this.currentPage || 1;
      this.$store.dispatch("employees/fetchEmployees", page);
    },
    resetData() {
      this.query = "";
      let page = 1;
      this.$store.dispatch("employees/fetchEmployees", page);
    },

    editItem(item) {
      this.editedIndex = this.employees.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.employees[this.editedIndex], this.editedItem);
        this.$store.dispatch("editEmployees", this.editedItem);
        this.isError = false;
        this.snackBar = true;
        this.snackBarText = "Employees Edited Succesfully";
      } else {
        this.employees.push(this.editedItem);
        this.isError = false;
        this.snackBar = true;
        this.snackBarText = " Employees Created Succesfully";
      }
      this.close();
    },
    async searchEmployees() {
      if (this.query) {
        await this.$store.dispatch("employees/searchEmployees", this.query);
      } else {
        this.snackBarText = " Search input cannot be empty";
        this.snackBar = true;
        this.closeSnackBar(1500);
      }
    },
    async paginatePage(e) {
      await this.$store.dispatch("employees/fetchEmployees", e);
      this.scrollToTop();
    },

    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.v-toolbar__title {
  font-weight: bolder;
  font-size: 1.2rem;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #404040;
  font-weight: bolder;
  font-size: 0.9rem;
}
</style>
